// Here you can add other styles
.login-logo{
    position: relative;
    &::after{
        position: absolute;
        content: "DSA dashboard is Product of - RAINBOW FINCORP";
        left: 0;
        bottom: 0;
        right: 0;
        height: 10%;
        background-color: white;
        text-align: center;
        font-size: 12px;
    }
}
.sidebar-logo-brand{
    background-color: white;
    border-right: 1px solid lightgrey;
}